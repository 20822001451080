<template>
  <b-container class="container-rda siderbar" style="max-width: 100%">
    <b-row class="height">
      <sidebar-admin />
      <b-col class="content">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h2 class="purple bold">Blog</h2>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="large"
              style="background-color: #ef6549"
              @click="deleteBlogCache"
              >Deletar cache</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda";
import SidebarAdmin from "../../../components/SidebarAdmin.vue";

export default {
  components: {
    SidebarAdmin,
  },

  data() {
    return {
      name: "blogs",
      loading: false,
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Blog",
          href: "/administracao/blogs",
        },
      ],
      events: [],
    };
  },

  methods: {
    async deleteBlogCache() {
      this.loading = true;

      try {
        await httpRda.delete("/blog").then(() => {
          this.loading = false;
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Cache deletado com sucesso",
          });
        });
      } catch (error) {
        this.loading = false;
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel deletar o cache!",
          footer: "Por favor, tente novamente mais tarde.",
        });
      }
    },
  },

  beforeMount() {
    if (this.rdaUnit !== "BELO_HORIZONTE") {
      this.$router.push({ name: "indicadores" });
    }
  },
};
</script>

<style></style>
